<template>
    <div class="background-footer">
      <div v-if="!nobleMode" class="left-side-footer">
        <div tabindex="0">
          362 Grand Street
        </div>
        <div tabindex="0">
          flophousecomedyclub@gmail.com
        </div>
      </div>
      <div :class="`right-side-footer ${nobleMode ? 'mobile-' : ''}shift`">
        <div class="icon-container">
          <a href="https://instagram.com/flophousecomedyclub?igshid=NTZkN2huaXJuOGM3">
            <div 
              class="icon-circle"
              v-on:click="goToInstagram"
              aria-label="Link to flop house comedy instagram."
              tabindex="0"
            >
              <font-awesome-icon 
                class="icon" 
                :icon="['fab', 'instagram']" 
              />
            </div>
          </a>
          <a href="https://gmail.us4.list-manage.com/subscribe?u=ea9fa57c167f97d4071cf616b&id=22f4f632c8">
            <div 
              class="icon-circle" 
              tabindex="0"
              aria-label="Link to email list."
            >
              <font-awesome-icon 
                class="icon" 
                :icon="['fab', 'mailchimp']" 
              />
            </div>
          </a>
          <a href="https://www.google.com/maps/place/Flop+House+Comedy+Club/@40.7122531,-73.9557515,15z/data=!4m6!3m5!1s0x89c259529f20a5d3:0xf3e741858868409a!8m2!3d40.7122531!4d-73.9557515!16s%2Fg%2F11y1rdszj9?entry=ttu">
            <div 
              class="icon-circle"
              tabindex="0"
              aria-label="Link to Flop House Directions."
            >
              <font-awesome-icon 
                class="icon" 
                :icon="['fas', 'fa-location-dot']" 
              />
            </div>
          </a>
        </div>
      </div>
    </div>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
 export default {
    name: 'AboutPage',
    components: {
      FontAwesomeIcon
    },
    props: {
        fixed: Boolean
    },
    computed: {
      nobleMode() {
        return this.isMobleDevice
      }
    },
    watch:{
        $route (){
            this.startNav = window.location.pathname
        }
    },
    data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return {
          isMobleDevice,
          startNav: window.location.pathname
        }
    }
}
</script>
  
<style scoped>
 .left-side-footer {
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 100px;
  }
  .right-side-footer {
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  .mobile-shift {
    margin-right: auto;
  }
  .shift {
    margin-right: 75px;
  }
  .background-footer {
    background: black;
    width: 100%;
    height: 100px;
    z-index: 1;
    color: white;
    display: flex;
  }
  .icon {
    font-size: xx-large;
    color: black;
    margin: auto;
  }
  .icon-circle:hover {
    background: #f72ff4;
  }
  .icon-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: white;
    display: flex;
    cursor: pointer;
  }
  .icon-container {
    width: 200px;
    display: flex;
    justify-content: space-evenly;
  }
</style>