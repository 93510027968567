<template>
    <div class="background-header" />
    <div 
      :class="`about-container${isMobleDevice ? '-mobile' : ''}`"
      role="img"
      aria-label="Croud of people laughing."
    >
      <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
        <div class="title" tabindex="0">
          Flop House Merch Store
        </div>
        <div :class="`text-container${isMobleDevice ? '-mobile' : ''}`">
          <div class="second-text">
            Merch coming soon!  Pre-order below.  Please note, pre-order closes 11/15 and items are expected to be delivered or picked up the first week of December. 
          </div>
          <div class="list-merch">
            <div
              v-bind:key="`merch-${index}`" 
              v-for="({ name, url, price }, index) in merch">
              <div class="merch-container">
              <n-card 
                    content-style="display: flex; justify-content: center; padding: 0px"
                    header-style="padding-top: 10px; padding-bottom: 10px; font-size: x-large; "
                    header-extra-style="font-size: large; max-width: 170px; text-align: center; text-decoration: none;"
                  >
                  <template #header>
                    <a class="link" :href="url" target="_blank"> {{ name }} </a>
                  </template>
                  <template #header-extra>
                    ${{ price }}
                  </template>
                  <n-carousel autoplay show-arrow :class="`carousel-container${isMobleDevice ? '-mobile' : ''}`">
                    <template v-for="(image, index2) in images[index]" v-bind:key="`image-${index}-${index2}`">
                      <img
                        class="carousel-img"
                        alt="Image of Flop House Comedy"
                        :src="image"
                      >
                    </template>
                  </n-carousel>
                </n-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerbar/>
  </template>
  
<script>
  import Footerbar from '../components/Footerbar.vue';
  import { merch } from '../apis/merchItems';
  import { NCard, NCarousel } from 'naive-ui';
  import { inject } from 'vue';
    export default {
      name: 'MerchPage',
      components: {
        Footerbar,
        NCard,
        NCarousel
      },
      data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const affiliate = inject('aff');
        if(this.$route.query?.aff ?? 'base' !== affiliate) {
          this.$route.query.aff = affiliate; 
          history.replaceState(history.state, null, `?aff=${affiliate}`);
        }
        const images = merch.map(({ images }) => images.map(image => require(`../assets/merch/${image}`)));
        return {
            isMobleDevice,
            merch,
            images
        }
      },
    }
  </script>
  
  <style scoped>
  .list-merch {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .flip-container {
    font-size: large;
    position: absolute;
    z-index: 3;
    color: white;
    bottom: 0;
    right: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
  }
  .merch-container {
    width: 300px;
    height: 400px;
  }
  .merch-img {
    width: 300px;
    height: 300px;
  }
  .merch-name {
    text-align: center;
    font-size: x-large;
    height: 40px;
  }
  .card-container{
    width: 300px;
    cursor: pointer;
    font-family: system-ui;
  }
  .img-description {
    width: 100%;
    user-select: none;
    object-fit: cover;
    height: 300px;
    z-index: 1;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }
  .title {
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .flouders-pic {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
  }
  .inner-about-container {
    width: 900px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
  }
  .carousel-container {
    height: 300px;
  }
  .carousel-container-mobile {
    height: 300px;
  }
  .about-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .about-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .second-text{
    margin-bottom: 20px;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  </style>