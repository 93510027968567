<template>
  <div class="main-section" :style="`--shrunk-height: ${isMobleDevice ? 500 : windowViewport * 0.80};`">
  <div 
    ref="mainContainer"
    :class="`background-container background-image${isMobleDevice ? '-moble' : ''}`" 
    style="height: calc(var(--shrunk-height) * 1px);"
    role="img" 
    aria-label="Flop House Comedy"
    decoding="async"
    tabindex="2"
    fetchPriority="high"
  >
    <div class="title-contianer">
      <div class="icon-grid-container">
        <div class="inner-subtext-small">
          <a href="https://instagram.com/flophousecomedyclub?igshid=NTZkN2huaXJuOGM3">
            <div 
              class="inner-inner"  
            >
              Follow us on Instagram
              <font-awesome-icon 
                class="icon" 
                :icon="['fab', 'instagram']" 
              />
            </div>
          </a>
          </div>
          <div class="inner-subtext-small">
            <a href="https://gmail.us4.list-manage.com/subscribe?u=ea9fa57c167f97d4071cf616b&id=22f4f632c8">
              <div 
              class="inner-inner"
              tabindex="0"
              aria-label="Link to email list."
            >
              Join our email list
              <font-awesome-icon 
                class="icon" 
                :icon="['fab', 'mailchimp']" 
              />
          </div>
            </a>
          </div>
          <div class="inner-subtext-small">
            <a href="https://www.google.com/maps/place/Flop+House+Comedy+Club/@40.7122531,-73.9557515,15z/data=!4m6!3m5!1s0x89c259529f20a5d3:0xf3e741858868409a!8m2!3d40.7122531!4d-73.9557515!16s%2Fg%2F11y1rdszj9?entry=ttu">
              <div 
                class="inner-inner" 
                tabindex="0"
                aria-label="Link to Flop House Directions."
              >
                362 Grand St, Brooklyn, NY 11211
              </div>
            </a>
          </div>
          <div class="inner-subtext-small">
            <n-button class="weekly-button icon-filter" type="white" dashed v-on:click="goToWeeksEvents">
              <div style="font-size: x-large;">
                <font-awesome-icon
                  class="icon-filter"
                  style="padding-right: 3px;"
                  :icon="['fas', 'fa-arrow-down']" 
                />
                Weekly Events
                <font-awesome-icon
                  class="icon-filter"
                  style="padding-left: 3px;"
                  :icon="['fas', 'fa-arrow-down']" 
                />
              </div>
            </n-button>
          </div>
        </div>
      </div>
  </div>
  
  <div class="white-space" ref="break"/>
  <ListItems/>
  </div>
  <footerbar/>
</template>

<script>
import { NButton } from 'naive-ui';
  import Footerbar from '../components/Footerbar.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import ListItems from "../components/ListItems";
  export default {
    name: 'HomePage',
    components: {
      ListItems,
      FontAwesomeIcon,
      Footerbar,
      NButton
    },
    data() {
      const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return {
        isMobleDevice,
        windowViewport: window.innerHeight,
      }
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.windowViewport = window.innerHeight;
      });
    },
    methods: {
      goToWeeksEvents() {
        this.$refs.break.scrollIntoView({behavior: "smooth"});
      }
    }
  }
</script>
<style scoped>
  .main-section {
    background: black;
  }
  .weekly-button {
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000;
  }
  .icon-filter {
    filter: 
      drop-shadow(-1px -1px 0px #000000) 
      drop-shadow(1px -1px 0px #000000) 
      drop-shadow(1px 1px 0px #000000) 
      drop-shadow(1px 1px 0px #000000);
  }
  .inner-inner {
    width: fit-content;
    cursor: pointer;
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000;
    padding-right: 2px;
    padding-left: 2px;
    border-radius: 5px;
    text-decoration: underline;
    color: white;
  }
  .white-space {
    height: 15px;
    width: 100%;
    background: white;
  }
  .icon-grid-container {
    grid-row: 10;
    height: fit-content;
    font-family: monospace;
    font-weight: 600;
  }
  .background-image {
    background-image: url("../assets/webgif.gif");
    background-repeat: no-repeat, no-repeat;
    background-position-x: center;
    background-position-y: -6rem;
    background-size: contain;
  }
  .background-image-moble {
    background-image: url("../assets/moble-webgif.gif");
    background-repeat: no-repeat, no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 110px;
  }
  .background-container {
    height: calc(var(--viewportHeight) * 1px);
  }
  .inner-subtext {
    color: lightgrey;
    font-size: xx-large;
    user-select: none;
    text-align: center;
  }
  .inner-text {
    color: white;
    font-size: xxx-large;
    user-select: none;
    text-align: center;
  }
  .title-contianer {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    z-index: 2;
  }
  .inner-subtext-small {
    color: white;
    font-size: x-large;
    user-select: none;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .icon {
    font-size: 30px;
    padding-left: 3px;
    margin-bottom: -2px;
    filter: 
      drop-shadow(-1px -1px 0px #000000) 
      drop-shadow(1px -1px 0px #000000) 
      drop-shadow(1px 1px 0px #000000) 
      drop-shadow(1px 1px 0px #000000);
  }
</style>