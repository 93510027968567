<template>
    <div class="background-header" />
    <div 
      :class="`about-container${isMobleDevice ? '-mobile' : ''}`"
      role="img"
      aria-label="Croud of people laughing."
    >
      <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
        <div class="title" tabindex="0">
          Open Mics
        </div>
        <div :class="`text-container${isMobleDevice ? '-mobile' : ''}`">
          <div class="raffle-explained">
            Each time you sign up for one of our open mics, your name will be added to our weekly raffle for a chance to win a guest spot on one of our shows. Your name will be added once for every open mic attended, so the more open mics you attend, the better your chances to win!
          </div>
          <br>
          <div class="open-mics">
            <MicItem day-of-week="Sunday" :mics="Sunday" />
            <MicItem day-of-week="Monday" :mics="Monday" />
            <MicItem day-of-week="Tuesday" :mics="Tuesday" />
            <MicItem day-of-week="Wednesday" :mics="Wednesday" />
            <MicItem day-of-week="Thursday" :mics="Thursday" />
            <MicItem day-of-week="Friday" :mics="Friday" />
            <MicItem day-of-week="Saturday" :mics="Saterday" />
          </div>
          <br>
        </div>
      </div>
    </div>
    <footerbar/>
  </template>
  
<script>
  import Footerbar from '../components/Footerbar.vue';
  import { openMics } from '../apis/openMics';
  import MicItem from '../components/MicItem.vue'
  import { inject } from 'vue';
    export default {
      name: 'OpenMicsPage',
      components: {
        Footerbar,
        MicItem
      },
      data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const affiliate = inject('aff');
        if(this.$route.query?.aff ?? 'base' !== affiliate) {
          this.$route.query.aff = affiliate; 
          history.replaceState(history.state, null, `?aff=${affiliate}`);
        }
        const micsByDay = {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saterday: []
        }
        openMics.forEach(({ name, dayOfWeek, startTime, endTime, owner, notes}) => 
            micsByDay[dayOfWeek].push({
                name,
                startTime,
                endTime,
                owner,
                notes
            }));
        return {
            isMobleDevice,
            ...micsByDay
        }
      },
    }
  </script>
  
  <style scoped>
  .raffle-explained {
    text-align: center;
  }
  .open-mics{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .flouders-pic {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
  }
  .inner-about-container {
    width: 900px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
  }
  .about-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .about-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  </style>