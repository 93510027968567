export const cleanLocalStorage = () => {
    Object.keys(localStorage).forEach(id => {
        try {
            const { endTime } = JSON.parse(localStorage.getItem(id));
            if (endTime < Date.now()) {
                localStorage.removeItem(id);
            }
        } catch {
            localStorage.removeItem(id);
        }
        
    });
};