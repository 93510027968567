<template>
    <RouterLink :to="`/comedians/${pageName}`" style="text-decoration: none;">
        <div class="outer-card-container">
            <n-card content-style="display: flex; padding: 0px">
                <div class="inner-card-container">
                    <img
                        tabindex="0"
                        class="img-description" 
                        alt="Comic Image"
                        src="stockImage.jpeg"
                        fetchPriority="low"
                        width="100"
                        height="100"
                        ref="image"
                        />
                    <div class="card-info">
                        <RouterLink :to="`/comedians/${pageName}`" class="card-header">
                        {{ name }}
                        </RouterLink>
                        <div class="card-description">
                            {{ bio }}
                        </div>
                    </div>
                </div>
            </n-card> 
        </div>
    </RouterLink>  
</template>
  
<script>
import { NCard } from 'naive-ui';
import { RouterLink } from 'vue-router'
import { getComicImage } from '../utils/getComicImage';
 export default {
    name: 'ComicCard',
    components: {
        NCard,
        RouterLink
    },
    props: {
        name: String,
        bio: String,
        pageName: String,
        imageId: String,
        imageDB: Object,
    },
    async mounted() {
        this.$refs.image.src = await getComicImage(this.imageDB, this.imageId);
    },
}
</script>
  
<style scoped>
.card-description {
  max-width: 300px;
  max-height: 160px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}
.card-info {
    padding-left: 10px;
    padding-right: 10px;
}
.card-header {
    font-size: x-large;
    height: 40px;
}
.inner-card-container {
    text-align: center;
    display: flex;
    width: 100%;
    max-width: 500px;
    max-height: 200px;
}
.outer-card-container {
    cursor: pointer;
    margin-bottom: 10px;
    max-width: 500px;
}
.img-description{
    width: 200px;
    object-fit: cover;
    height: 200px;
    z-index: 1;
}
</style>