function indexedDBSupport(){
    return 'indexedDB' in window;
}

export function getImageDB() {
    return new Promise((resolve) => {
        if (!indexedDBSupport()) resolve(null);

        const request = indexedDB.open('images', 1);

        request.onerror = () => resolve(null);

        request.onsuccess = () => {
            const db = request.result;            
            resolve({
                addImage(image) {
                    return new Promise((resolve) => {
                        const transaction = db.transaction('images', "readwrite").objectStore('images').add(image);
                        transaction.oncomplete = () => resolve('added');
                        transaction.onerror = () => resolve('failed');
                        transaction.onsuccess = () => resolve(transaction.result);
                    });
                },
                getImage(imageId) {
                    return new Promise((resolve) => {
                        const transaction = db.transaction('images').objectStore('images').get(imageId);
                        transaction.oncomplete = () => resolve(transaction.result);
                        transaction.onerror = () => resolve(null);
                        transaction.onsuccess = () => resolve(transaction.result);
                    });
                }});
        };

        request.onupgradeneeded = () => {
            const db = request.result;
            const objectStore = db.createObjectStore('images', {keyPath: 'imageId'});
            objectStore.createIndex("imageId", "imageId", { unique: true });
            objectStore.createIndex("imageBlob", "imageBlob", { unique: false });
        };
    });

}
