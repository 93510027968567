<template>
   <n-config-provider :theme-overrides="themeOverrides">
    <router-view />
    <NavbarComponent />
   </n-config-provider>
</template>
<script>
  import NavbarComponent from './components/Navbar.vue';
  import { getCal } from "./apis/getCal";
  import { provide } from 'vue'
  import { NConfigProvider } from 'naive-ui';
  import { cleanLocalStorage } from "./utils/cleanLocalStorage";

  export default {
    components: {
      NavbarComponent,
      NConfigProvider
    },
    setup() {
      const pageEvents = getCal();
      cleanLocalStorage();
      provide("pageEvents", pageEvents);
      const { affiliate } = JSON.parse(localStorage.getItem('affiliate'));
      provide('aff', affiliate);
      return {
        themeOverrides: {
          Pagination: {
            itemTextColorActive: "#f72ff4",
            itemBorderActive: "1px solid #f72ff4",
            itemTextColorHover: "#f72ff4",
            itemTextColor: "white",
            buttonIconColor: "white",
            itemTextColorDisabled: "gray",
            buttonIconColorHover: "#f72ff4"
          },
          Input: {
            borderFocus: "#f72ff4",
            borderPressed: "#f72ff4",
            borderHover: "#f72ff4",
            loadingColor: "#f72ff4",
            borderFocusWarning: "#f72ff4",
            borderHoverWarning: "#f72ff4",
            caretColor: "#f72ff4",
            
          },
          Button: {
            border: "1px solid rgba(0, 0, 0, 0.5)",
            borderHover: "gray",
            borderFocus: "#f72ff4",
            borderPressed: "#f72ff4",
            textColorHover: "gray",
            textColorFocus: "gray",
            textColorPressed: "#f72ff4",
            rippleColor: "#f72ff4",
            textColorDisabled: "gray"
          },
        }
      }
    }
  }
</script>