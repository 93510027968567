  <template>
    <div class="background-header" />
    <div class="calendar-container">
      <template v-if="!isMobleDevice">
            <div class="calendar">
                <n-calendar
                  style="height: auto; min-height: 700px;"
                  #="{ year, month, date }"
                  :theme-overrides="themeOverrides"
                > 
                  <div 
                    v-bind:key="`event-${index}`" 
                    v-for="({ title, url, description, time, daytime }, index) in pageEvents.get(`${year}-${month}-${date}`)">
                      <EventComponent 
                        :title="title"
                        :url="url"
                        :description="description"
                        :time="time"
                        :isDaytime="daytime"
                      />
                  </div>
                </n-calendar>
            </div>
      </template>
      <template v-if="isMobleDevice">
        <div :style="`min-height: ${mobileHeight - 210}px`">
          <n-list style="background: #151515;">
            <div v-bind:key="`event-${index}`" v-for="(dayEvents, index) in pageEventObjs">
              <n-list-item>
                <div class="date-title">
                  {{ dayEvents.day }}
                </div>
              </n-list-item>
              <n-list-item
                  v-bind:key="`event-${index}`" 
                  v-for="({ title, time, url }, index) in dayEvents.events"
                >
                <template v-if="url === ''">
                  <n-thing>
                    <template #header>
                        <div class="item-title" style="color: white;">
                          {{ title }}
                        </div>
                      </template>
                      <template #header-extra>
                        <div style="margin-right: 15px; color: white;">
                          {{ time }}
                        </div>
                      </template>
                  </n-thing>
                </template>
                <template v-else>
                  <a class="link-container" :href="`${url.split('?aff=')[0]}?aff=${aff}`">
                    <n-thing>
                      <template #header>
                        <div class="link item-title" :href="`${url.split('?aff=')[0]}?aff=${aff}`">
                          {{ title }}
                        </div>
                      </template>
                      <template #header-extra>
                        <div style="margin-right: 15px; color: white;">
                          {{ time }}
                        </div>
                      </template>
                    </n-thing>
                  </a>
                </template>
                </n-list-item>
                <br/>
                <br>
            </div>
          </n-list>
        </div>
      </template>
    </div>
    <footerbar/>
  </template>

  <script>
  import EventComponent from "../components/Event"
  import Footerbar from '../components/Footerbar.vue';
  import { NCalendar, NThing, NListItem, NList } from 'naive-ui'
  import { inject } from "vue";
  export default {
    name: 'EventsPage',
    components: {
      NCalendar,
      EventComponent,
      Footerbar,
      NThing, 
      NListItem, 
      NList
    },
    data () {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const pageEvents = inject("pageEvents");
      const pageEventObjs = [];
      if(isMobleDevice) {
        pageEvents.forEach((events, date) => {
          const [year, month, day] = date.split('-');
          const dayName = new Date(year, month - 1, day).toLocaleDateString("default", { weekday: 'long' }); 
          pageEventObjs.push({day: `${dayName} ${monthNames[month - 1]} ${day}`, events})
        });
      }
      return {
        aff: inject('aff'),
        isMobleDevice,
        mobileHeight: window.innerHeight,
        pageEventObjs,
        themeOverrides: {
          cellColorHover: "transparent",
          barColor: "transparent",
          dateColorCurrent: "gray", //current date color
        },
        pageEvents
      }
    },
    methods: {
      setShowing(dayEvents) {
        dayEvents.showing = !dayEvents.showing;
      }
    }
  }
  </script> 
  <style>
    .link {
      color: #f72ff4;
      text-decoration-line: underline;
    }
    .item-title {
      max-width: 300px;
      margin-left: 15px;
    }
    .date-title{
      color: white;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
    }
    .calendar-container {
      padding-top: 110px;
      background: white;
      width: 100%;
    }
    .calendar {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      min-width: 800px;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-right: 10px;
      padding-left: 10px;
    }
    .link-container {   
        text-decoration: none;
        color: black;
    }
    .background-header {
      background: black;
      width: 100%;
      height: 110px;
      position: absolute;
      z-index: 1;
    }
  </style>