<template>
    <div class="card-container">
      <a :href="url" style="text-decoration: none;">
        <n-card 
          :title="`${monthString} ${dayOfWeek}`"
          content-style="display: flex; justify-content: center; padding: 0px"
          header-style="padding-top: 10px; padding-bottom: 10px; font-size: x-large;"
          header-extra-style="font-size: large; max-width: 170px; text-align: center;"
        >
          <template #header-extra>
            <a class="link" :href="url">{{ title }}</a>
          </template>
          <img
            tabindex="0"
            class="img-description" 
            src="placeholder.png"
            alt="Flyer for Comedy event happening this week"
            fetchPriority="low"
            width="300"
            height="300"
            ref="image"
          />
          <div class="time-container">
            {{ time }}
          </div>
        </n-card>
      </a>
    </div>
</template>
  
<script>
  import { NCard } from 'naive-ui';
  import { monthToString } from "../utils/monthToString";
  import { getImage } from "../utils/getImage";
  export default {
    name: 'WeeklyEvent',
    components: {
        NCard,
    },
    props: {
        title: String,
        url: String,
        description: String,
        time: String,
        dayOfWeek: Number,
        month: Number,
        isDaytime: Boolean,
        end: Number
    },
    data () {
      return {
        monthString: monthToString(this.month)
      }
    },
    async mounted() {
      this.$refs.image.src = await getImage(this.description, this.end);
    },
  }
</script>  
<style scoped>
  .link {
    color: #f72ff4;
  }
  .card-container{
    width: 300px;
    cursor: pointer;
    font-family: system-ui;
  }
  .img-description{
    width: 100%;
    object-fit: cover;
    height: 300px;
    z-index: 1;
  }
  .time-container{
    font-size: large;
    position: absolute;
    z-index: 3;
    color: white;
    bottom: 0;
    right: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000;
  }
</style>