<template>
    <div class="background-header" />
    <div 
      :class="`about-container${isMobleDevice ? '-mobile' : ''}`"
      role="img"
      aria-label="Croud of people laughing."
    >
      <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
        <div class="title" tabindex="0">
          About Us
        </div>
        <div :class="`text-container${isMobleDevice ? '-mobile' : ''}`">
          <div tabindex="0">
            Flop House Comedy Club was founded by comedian Nicholas Hopping and his brother Nathan Hopping to ensure an inviting experience featuring AMAZING lineups, affordable tickets, no drink minimums, and comedians from Netflix, HBO and more. Check us out at @flophousecomedyclub for information on tickets, lineups, pop-ups, space rentals, and more!
          </div>
          <br>
          <div tabindex="0">
            Located at 362 Grand Street. If you have any questions, want to produce a show with us, or want to rent the space during the day, email us at flophousecomedy@gmail.com
          </div>
        </div>
        <img
          class="flouders-pic"
          :style="isMobleDevice ? 'width : 80%; display: flex;' : ''"
          src="../assets/Flounders3.png"
          alt="Founders of the Comedy club."
          tabindex="0"
        />
      </div>
    </div>
    <footerbar/>
  </template>
  
<script>
  import Footerbar from '../components/Footerbar.vue';
  import { inject } from 'vue';
    export default {
      name: 'AboutPage',
      components: {
        Footerbar
      },
      data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const affiliate = inject('aff');
        if(this.$route.query?.aff ?? 'base' !== affiliate) {
          this.$route.query.aff = affiliate; 
          history.replaceState(history.state, null, `?aff=${affiliate}`);
        }
        return {
            isMobleDevice
        }
      },
    }
  </script>
  
  <style scoped>
  .title {
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .flouders-pic {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
  }
  .inner-about-container {
    width: 900px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
  }
  .about-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .about-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  </style>