import { apiResp } from "./apiResp";

const getHourMin = (date) => {
    const hour = date.getHours();
    const min = `${date.getMinutes()}`;
    const printableHour = hour % 12;
    return `${printableHour === 0 ? 12 : printableHour}:${min.length === 1 ? `${min}0` : min}${hour > 12 ? "pm" : "am"}`
};

//Peacock => '' => Night Event on Homepage
//Banana => 5 => Night Event on Homepage (Produced Shows/We dont book shows)
//Sage => 2 => Night Event not on Homepage
//Tangarine => 6 => Day Event on Homepage
//Tomato => 11 => Day Event not on homepage

export const getCal = () => {
    return apiResp.reduce((events, event) => {
        const { title, url, description, startTime, endTime, color } = event;
        const start = new Date(startTime);
        const end = new Date(endTime);
        if(end.getTime() < new Date().getTime()) {
            return events;
        }
        const date = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
        const list = events.get(date) || [];
        list.push({
            title: title.replaceAll("^", "'"),
            description,
            url: decodeURIComponent(url),
            time: `${getHourMin(start)} - ${getHourMin(end)}`,
            daytime: color === "6" || color === "11",
            showFrontPage: color === undefined || color === '' || color === "6" || color === "5",
            end: endTime
        });
        return events.set(date, list);
    }, new Map());
}