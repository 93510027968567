<template>
    <div class="background-header" />
        <div 
        :class="`about-container${isMobleDevice ? '-mobile' : ''}`"
        role="img"
        aria-label="Croud of people laughing."
        >
        <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
            <div class="title" tabindex="0">
                Comedians
            </div>
            <div class="search-container">
                <div class="search-input">
                    <n-input
                        v-model:value="searchValue"
                        placeholder="Search for specific Comedian"
                        @keyup="handleInput"
                    />
                </div>
                <n-button color="#f72ff4" @click="search"> Search </n-button>
            </div>
            <div :class="`pagination-container${isMobleDevice ? '-mobile' : ''}`" v-if="comicList.length > 8">
              <n-pagination v-model:page="page" :page-count="pageCount" />
            </div>
            <div v-if="imageDB !== null" class="list-container">
                <div v-bind:key="`comic-${pageName}`"  v-for="({ name, bio, imageId, pageName }) in comicList.slice((page - 1) * 8, ((page - 1) * 8) + 8)">
                    <ComicCard :name="name" :bio="bio" :imageId="imageId" :pageName="pageName" :imageDB="imageDB"/>
                </div>
            </div>
            <div :class="`pagination-container${isMobleDevice ? '-mobile' : ''}`" v-if="comicList.length > 8">
              <n-pagination v-model:page="page" :page-count="pageCount" />
            </div>
        </div>
    </div>
    <footerbar/>
</template>
  
<script>
    import { NInput, NButton, NPagination } from 'naive-ui';
    import Footerbar from '../components/Footerbar.vue';
    import ComicCard from  '../components/ComicCard.vue';
    import { comicInfo } from '@/apis/comicsInfo';
    import { getImageDB } from '../utils/indexDB';
    export default {
      name: 'ComediansPage',
      components: {
        Footerbar,
        ComicCard,
        NInput,
        NButton,
        NPagination
      },
      computed: {
        pageCount() {
          return Math.ceil(this.comicList.length / 8);
        }
      },
      data () {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return {
            isMobleDevice,
            comicList: [...comicInfo],
            comicInfo,
            searchValue: "",
            imageDB: null,
            page: 1,
        }
      },
      async created() {
        this.imageDB = await getImageDB();
      },
      methods: {
        handleInput({ key }) {
          if(key === "Enter") {
            this.search();
          }
        },
        search() {
          this.comicList = this.comicInfo.filter(({ name }) => name.toLowerCase().includes(this.searchValue.toLowerCase().trim()));
          this.page = 1;
        }
      }
    }
  </script>
  
  <style scoped>
  .search-container {
    display: flex;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
  }
  .title {
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .pagination-container {
    margin-left: auto;
    margin-right: 10%;
    padding-bottom: 10px;
  }
  .pagination-container-mobile {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding-bottom: 10px;
  }
  .inner-about-container {
    width: 1400px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .search-input {
    width: 100%;
    margin-right: 10px;
  }
  .list-container {
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
  }
  .about-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .about-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  </style>