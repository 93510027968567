import { blobToBase64 } from "./blobToBase64";

export const getImage = async (id, endTime) => {
    if(!localStorage.getItem(id)) {
        const resp = await fetch(`https://d2tq44tyvd0k46.cloudfront.net/${id}`);
        if(!resp.ok) return "placeholder.png";
        const blob = await resp.blob();
        const text = id.startsWith('web-') ? await blobToBase64(blob) : await blob.text();
        try {
            localStorage.setItem(id, JSON.stringify({img: text, endTime}));
        } catch (e) {
            return text;
        }
       
    }
    return JSON.parse(localStorage.getItem(id)).img;
};