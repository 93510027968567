<template>
    <div :class="`event-container ${isDaytime ? 'daytime-event' : 'nighttime-event'}`">
        <a v-if="url !== ''" class="link-container" :href="`${url.split('?aff=')[0]}?aff=${aff}`">
          <div class="event-title">
            <b>
                {{ title }}
            </b>
         </div>
         <div class="event-body">
            {{ time }}
         </div>
        </a>
        <div v-else>
            <div class="event-title">
            <b>
                {{ title }}
            </b>
            </div>
            <div class="event-body">
                {{ time }}
            </div>
        </div>
    </div>
</template>
  
<script>
  import { inject } from 'vue'
  export default {
    name: 'EventComponent',
    props: {
        title: String,
        url: String,
        description: String,
        time: String,
        isDaytime: Boolean
    },
    data () {
      return {
        aff: inject('aff')
      }
    },
  }
</script>  
<style scoped>
    .link-container {   
        text-decoration: none;
        color: black;
    }
    .daytime-event {
        background: rgb(230, 173, 173);
    }
    .nighttime-event {
        background: lightblue;
    }
   .event-container {
        border: 1px solid var(--n-border-color);
        border-radius: var(--n-border-radius);
        margin-top: 4px;
   }
   .event-title {
        margin-left: 5px;
        font-size: larger;
        word-break: break-word;
   }
   .event-body {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        padding: 5px;
   }
   .event-description {
        width: fit-content;
   }
</style>