<template>
    <div class="background-header" />
    <div 
      :class="`comic-container${isMobleDevice ? '-mobile' : ''}`"
      role="img"
      aria-label="Croud of people laughing."
    >
      <div :class="`inner-about-container${isMobleDevice ? '-mobile' : ''}`">
        <div class="title" tabindex="0">
          {{ name }}
        </div>
        <div :class="`text-container${isMobleDevice ? '-mobile' : ''}`">
          <div tabindex="0" style="text-align: center;">
            {{ bio }}
          </div>
        </div>
        <img
          class="flouders-pic"
          :style="isMobleDevice ? 'width : 80%; display: flex;' : ''"
          src="stockImage.jpeg"
          ref="image"
          alt="Comic Picture."
          tabindex="0"
        />
      </div>
    </div>
    <footerbar/>
  </template>
  
<script>
  import Footerbar from '../components/Footerbar.vue';
  import { getComicImage } from "../utils/getComicImage";
  import { getImageDB } from '../utils/indexDB';
  import { comicInfo } from '@/apis/comicsInfo';
    export default {
      name: 'ComedianPage',
      components: {
        Footerbar
      },
      data() {
        const isMobleDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const { name, bio, imageId } = comicInfo.find(({ pageName }) => pageName === location.pathname.split('/')[2]);
        return {
            isMobleDevice,
            name,
            bio,
            imageId,
        }
      },
      async mounted() {
        window.scrollTo(0, 0);
        const imageDB = await getImageDB();
        this.$refs.image.src = await getComicImage(imageDB, this.imageId);
    },
    }
  </script>
  
  <style scoped>
  .title {
    width: fit-content;
    font-size: xxx-large;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .flouders-pic {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
  }
  .inner-about-container {
    width: 900px;
    min-height: 500px;
    display: grid;
    height: fit-content;
    margin-bottom: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: white solid 2px;
    border-bottom: white solid 2px;
    border-right: white solid 2px;
    background: #151515;
  }
  .inner-about-container-mobile {
    height: fit-content;
    background: #151515;
    min-height: 500px;
  }
  .comic-container {
    padding-top: 110px;
    display: flex;
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    justify-content: center;
    color: white;
  }
  .comic-container-mobile {
    padding-top: 110px;
    color: white;
  }
  .text-container {
    width: 800px;
    font-size: larger;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .text-container-mobile {
    font-size: medium;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    text-align: center;
  }
  .background-header {
    background: black;
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1;
  }
  </style>