export const merch = [
    {
        name: "FLOP TOP",
        url: "https://square.link/u/MbK9eqfg?src=embed",
        images: ["hat.png"],
        price: "10"
    },
    {
        name: "FLOP CROP",
        url: "https://square.link/u/J5PIvZzO?src=embed",
        images: ["flopCropFront.png", "flopCropBack.png"],
        price: "30"
    },
    {
        name: "FLOP HOUSE HOODIE",
        url: "https://square.link/u/6Xkv1tgN?src=embed",
        images: ["flopHoodieFront.png", "flopHoodieBack.png", "flopHoodieDescription.png"],
        price: "45"
    }
];