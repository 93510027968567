<template>
  <div class="outer-container">
    <div 
      class="list-container"
      role="img"
      aria-label="Croud of people laughing."
    >
    <template v-if="weeksEvents.length > 0">
      <div
        class="list-item"
        v-bind:key="`event-${index}`" 
        v-for="({ title, url, description, time, dayOfWeek, month, end }, index) in weeksEvents">
          <WeeklyEvent 
            :title="title"
            :url="`${url.split('?aff=')[0]}?aff=${aff}`"
            :description="description"
            :time="time"
            :dayOfWeek="dayOfWeek"
            :month="month + 1"
            :end="end"
          />
        </div>
    </template>
    <template v-else>
      <div class="more-events">
        More events coming soon!
      </div>
    </template>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import WeeklyEvent from "../components/WeeklyEvent"
export default {
    name: 'ListItems',
    components: {
        WeeklyEvent,
    },
    data () {
      const pageEvents = inject("pageEvents");
      const weeksEvents = [];
      const day = new Date();
      for(let i = 0; i < 7; i++) {
        const month = day.getMonth();
        const dayOfWeek = day.getDate();
        const eventsOnDate = pageEvents.get(`${day.getFullYear()}-${month + 1}-${dayOfWeek}`);
        if(eventsOnDate) {
            weeksEvents.push(...eventsOnDate.reduce((events, event) => {
              if(event.showFrontPage) {
                events.push({ ...event, month, dayOfWeek });
              }
              return events;
            }, []));
        }
        day.setDate(dayOfWeek + 1);
      }
      return {
        aff: inject("aff"),
        pageEvents,
        weeksEvents
      }
    },
}
</script>
<style scoped>
  .outer-container {
    background-image: url("../assets/backgroundOne.png");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 10px;
    min-height: 200px;
  }
  .list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .list-item {
    padding-bottom: 10px;
  }
  .more-events {
    color: white;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: xx-large;
  }
</style>